import React, { Component } from "react";

class ContactInfo extends Component {
  render() {
    return (
      <div className="ltn__contact-address-area mb-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
                <div className="ltn__contact-address-icon">
                  <img src="./assets/img/email.png" alt="Icon Image" width={50} height={50} />
                </div>
                <h3>E-mail</h3>
                <p>info@pb-centrum.cz</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
                <div className="ltn__contact-address-icon">
                  <img src="./assets/img/tel.png" alt="Icon Image" width={50} height={50} />
                </div>
                <h3>Telefonní číslo</h3>
                <p>+420 731 653 530</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
                <div className="ltn__contact-address-icon">
                  <img src="./assets/img/location.png" alt="Icon Image" width={50} height={50} />
                </div>
                <h3>Adresa</h3>
                <p>Mírové nám. 37, 400 01, Ústní nad Labem</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactInfo;
