import React, { Component } from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch } from "react-router-dom";

import Home from "./components/Home";
import Services from "./components/Services";
import Offices from "./components/Offices";
import About from "./components/About";
import Contact from "./components/Contact";

class Root extends Component {
  render() {
    return (
      <HashRouter basename="/">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/sluzby" component={Services} />
          <Route path="/kancelare" component={Offices} />
          <Route path="/o-nas" component={About} />
          <Route path="/kontaktujte-nas" component={Contact} />
        </Switch>
      </HashRouter>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
