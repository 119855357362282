import React, { Component } from "react";

class Map extends Component {
  render() {
    return (
      <div className="google-map mb-100">
        <iframe
          src="https://www.google.com/maps/embed/v1/place?q=OFFICE+CENTRUM+ÚSTÍ+/+PB-CENTRUM+spol.+s+r.o./,+Mírové+náměstí,+Ústí+nad+Labem-město-Ústí+nad+Labem-centrum,+Czechia&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
          width="100%"
          height="100%"
          frameBorder={0}
          allowFullScreen
          aria-hidden="false"
          tabIndex={0}
        />
      </div>
    );
  }
}

export default Map;
