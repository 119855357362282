import React from "react";

import Navbar from "./global-components/Navbar";
import PageHeader from "./global-components/PageHeader";
import CallToAction from "./global-components/CallToAction";
import Footer from "./global-components/Footer";
import Slider from "./section-components/Slider";
import PortfolioGrid from "./section-components/PortfolioGrid";

const Offices = () => {
  return (
    <>
      <Navbar />
      <PageHeader headerTitle="Jak to u nás vypadá?" />
      <Slider />
      <PortfolioGrid />
      <CallToAction />
      <Footer />
    </>
  );
};

export default Offices;
