import React, { Component } from "react";

class Service extends Component {
  render() {
    return (
      <div className="ltn__service-area pt-115 go-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center">
                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Moderní kanceláře</h6>
                <h1 className="section-title">Naše prostory</h1>
              </div>
            </div>
          </div>
          <div className="row  justify-content-center">
            <div className="col-lg-4 col-sm-6 col-12">
              <div
                className="ltn__feature-item ltn__feature-item-6 text-center bg-white box-shadow-1"
                style={{ height: "200px" }}>
                <div className="ltn__feature-info">
                  <h3>Na míru</h3>
                  <p>Upravíme dispozice kanceláří potřebám Vašeho podnikání.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div
                className="ltn__feature-item ltn__feature-item-6 text-center bg-white box-shadow-1"
                style={{ height: "200px" }}>
                <div className="ltn__feature-info">
                  <h3>Reprezentativní</h3>
                  <p>Vybudejte své zázemí v nejžádanější administrativní lokalitě Ústí nad Labem.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div
                className="ltn__feature-item ltn__feature-item-6 text-center bg-white  box-shadow-1"
                style={{ height: "200px" }}>
                <div className="ltn__feature-info">
                  <h3>Komfortní</h3>
                  <p>Vaše kanceláře budou ideálním místem pro pracovní schůzku i odpočinek.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Service;
