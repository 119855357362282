import React, { Component } from "react";

class Companies extends Component {
  render() {
    return (
      <div className="ltn__brand-logo-area ltn__brand-logo-1 pt-160 pb-170 plr--9">
        <div className="section-title-area ltn__section-title-2--- mb-30 text-center">
          <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Budete v dobré společnosti</h6>
          <h1 className="section-title mb-80 text-center">Kdo už sídlí v naší budově</h1>
        </div>
        <div className="container-fluid">
          <div className="row ltn__brand-logo-active">
            <div className="col-lg-12">
              <div className="ltn__brand-logo-item mt-25">
                <img src="./assets/img/companies/1.png" alt="Brand Logo" width={200} height={52} />
              </div>
            </div>
            <div className="col-lg-12">
              <div className="ltn__brand-logo-item mt-3">
                <img src="./assets/img/companies/2.png" alt="Brand Logo" width={200} height={63} />
              </div>
            </div>
            <div className="col-lg-12">
              <div className="ltn__brand-logo-item">
                <img src="./assets/img/companies/3.png" alt="Brand Logo" width={200} height={115} />
              </div>
            </div>
            <div className="col-lg-12">
              <div className="ltn__brand-logo-item">
                <img src="./assets/img/companies/4.jpeg" alt="Brand Logo" width={140} />
              </div>
            </div>
            <div className="col-lg-12">
              <div className="ltn__brand-logo-item">
                <img src="./assets/img/companies/5.png" alt="Brand Logo" width={110} height={108} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Companies;
