import React, { Component } from "react";

class Counter extends Component {
  render() {
    return (
      <div className="ltn__counterup-area pt-150 pb-70">
        <div className="container">
          <div className="row">
            <div className="section-title-area ltn__section-title-2--- mb-30 text-center">
              <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">V číslech</h6>
              <h1 className="section-title mb-30 text-center">Co u nás najdete</h1>
            </div>
            <div className="col-md-3 col-sm-6 align-self-center">
              <div className="ltn__counterup-item text-color-white---">
                <h1>
                  <span className="counter">6</span>
                </h1>
                <h6>Pater</h6>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 align-self-center">
              <div className="ltn__counterup-item text-color-white---">
                <h1>
                  <span className="counter">500</span>
                  <span className="counterUp-icon">m²</span>{" "}
                </h1>
                <h6>Kancelářské plochy</h6>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 align-self-center">
              <div className="ltn__counterup-item text-color-white---">
                <h1>
                  <span className="counter">12</span>
                  <span className="counterUp-icon">+</span>{" "}
                </h1>
                <h6>Zasedacích a školících místností</h6>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 align-self-center">
              <div className="ltn__counterup-item text-color-white---">
                <h1>
                  <span className="counter">8</span>
                  <span className="counterUp-icon"></span>{" "}
                </h1>
                <h6>Obchodních prostor</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Counter;
