import React from "react";

import Navbar from "./global-components/Navbar";
import PageHeader from "./global-components/PageHeader";
import Footer from "./global-components/Footer";
import CallToAction from "./global-components/CallToAction";
import History from "./section-components/History";

const About = () => {
  return (
    <>
      <Navbar />
      <PageHeader headerTitle="Jsme tady již 20 let" />
      <History />
      <CallToAction />
      <Footer />
    </>
  );
};

export default About;
