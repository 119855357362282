import React, { Component } from "react";

class PageHeader extends Component {
  render() {
    return (
      <div className="ltn__breadcrumb-area text-left bg-overlay-white-30">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="page-title">{this.props.headerTitle}</h1>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PageHeader;
