import React, { Component } from "react";

class Copyright extends Component {
  render() {
    return (
      <div className="ltn__copyright-area ltn__copyright-2 section-bg-2 plr--5">
        <div className="container-fluid ltn__border-top-2">
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="ltn__copyright-design clearfix">
                <p>
                  <span className="current-year" /> &copy; PB-CENTRUM spol. s r.o. Všechna práva vyhrazena.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Copyright;
