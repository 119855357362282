import React, { Component } from "react";
import { Link } from "react-router-dom";

class Banner extends Component {
  render() {
    return (
      <div className="ltn__slider-area ltn__slider-6 mb-120---">
        <div className="ltn__slide-one-active slick-slide-arrow-1 slick-slide-dots-1">
          {/* ltn__slide-item */}
          <div
            className="ltn__slide-item--- ltn__slide-item-9 section-bg-1 bg-image"
            data-bs-bg="./assets/img/main.webp">
            <div className="ltn__slide-item-inner">
              <div className="slide-item-info bg-overlay-white-90 text-center">
                <div className="slide-item-info-inner  ltn__slide-animation">
                  <h1 className="slide-title animated text-uppercase">Pronájem kanceláří</h1>
                  <h4 className="slide-sub-title text-uppercase animated">Ústní nad Labem</h4>
                  <div className="btn-wrapper animated go-top">
                    <Link to="/kontaktujte-nas" className="theme-btn-1 btn btn-effect-1 text-uppercase">
                      Kontaktujte nás
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*  */}
        </div>
      </div>
    );
  }
}

export default Banner;
