import React, { Component } from "react";

class Video extends Component {
  render() {
    return (
      <div className="ltn__about-us-area bg-image-right-before pb-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <div className="about-us-info-wrap">
                <div className="section-title-area ltn__section-title-2--- mb-20">
                  <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Myslíme na vše</h6>
                  <h1 className="section-title">Spokojenost našich klientů na prvním místě</h1>
                  <p>
                    Celá naše budova je vybavena nejmodernějšími technologiemi, které Vám usnadní práci a zvýší pohodlí.
                  </p>
                </div>
                <ul className="ltn__list-item-half ltn__list-item-half-2 list-item-margin clearfix">
                  <li>
                    <i className="icon-done" />
                    Profesionální ostraha
                  </li>
                  <li>
                    <i className="icon-done" />
                    Elektronické zabezpečení
                  </li>
                  <li>
                    <i className="icon-done" />
                    Podzemní garáže
                  </li>
                  <li>
                    <i className="icon-done" />
                    Moderní kanceláře
                  </li>
                  <li>
                    <i className="icon-done" />
                    Úklid a odvoz odpadu
                  </li>
                  <li>
                    <i className="icon-done" />
                    Bezbariérový přístup
                  </li>
                  <li>
                    <i className="icon-done" />
                    Technická výpomoc
                  </li>
                  <li>
                    <i className="icon-done" />
                    Vysokorychlostní internet
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="about-us-img-wrap about-img-left"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Video;
