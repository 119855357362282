import React, { Component } from "react";

class Slider extends Component {
  render() {
    return (
      <div
        className="ltn__upcoming-project-area section-bg-1--- bg-image-top pt-115 pb-65"
        data-bs-bg="./assets/img/elevator.webp">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center---">
                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Naše kancelářské prostory</h6>
                <h1 className="section-title  white-color">Prozkoumejte, co vše se u nás nachází</h1>
              </div>
            </div>
          </div>
          <div className="row ltn__upcoming-project-slider-1-active slick-arrow-3">
            {/* upcoming-project-item */}
            <div className="col-lg-12">
              <div className="ltn__upcoming-project-item">
                <div className="row">
                  <div className="col-lg-7">
                    <div className="ltn__upcoming-project-img">
                      <img src="./assets/img/office-spaces.webp" alt="#" />
                    </div>
                  </div>
                  <div className="col-lg-5 section-bg-1">
                    <div className="ltn__upcoming-project-info ltn__menu-widget">
                      <h1 className="mb-30">Kancelářské prostory</h1>
                      <p>
                        Pracujte v kancelářích, které jsou navrženy tak, aby vám poskytly profesionální a pohodlné
                        pracovní prostředí. Naše moderní kanceláře jsou vybaveny prvotřídním zařízením, abyste mohli
                        dosáhnout maximální efektivity. Vaše kancelář by měla být místem, kde se rodí vaše nejlepší
                        nápady, a to právě u nás.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* upcoming-project-item */}
            <div className="col-lg-12">
              <div className="ltn__upcoming-project-item">
                <div className="row">
                  <div className="col-lg-7">
                    <div className="ltn__upcoming-project-img">
                      <img src="./assets/img/conference-room.webp" alt="#" />
                    </div>
                  </div>
                  <div className="col-lg-5 section-bg-1">
                    <div className="ltn__upcoming-project-info ltn__menu-widget">
                      <h1>Zasedací a školící prostory</h1>
                      <p>
                        Naše zasedací místnosti jsou ideální pro efektivní setkání a kreativní brainstorming. Pro výuku
                        a rozvoj nabízíme moderní školící místnosti vybavené nejnovější technologií. U nás se vaše
                        schůzky a školení budou odehrávat v inspirujícím prostředí, které podpoří vaše úspěchy.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* upcoming-project-item */}
            <div className="col-lg-12">
              <div className="ltn__upcoming-project-item">
                <div className="row">
                  <div className="col-lg-7">
                    <div className="ltn__upcoming-project-img">
                      <img src="./assets/img/shop.webp" alt="#" />
                    </div>
                  </div>
                  <div className="col-lg-5 section-bg-1">
                    <div className="ltn__upcoming-project-info ltn__menu-widget">
                      <h1>Obchodní prostory</h1>
                      <p>
                        V našich obchodních prostorech najdete prostor pro realizaci vašich ambicí. Máme pro vás ideální
                        místo pro rozvoj vašeho podnikání, kde můžete plánovat růst a úspěch. Vaše obchodní budoucnost
                        začíná právě tady, v našich inspirujících prostorech.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*  */}
          </div>
        </div>
      </div>
    );
  }
}

export default Slider;
