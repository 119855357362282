import React, { Component } from "react";

class About extends Component {
  render() {
    return (
      <div className="ltn__about-us-area pt-115 pb-100 ">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <div className="about-us-img-wrap about-img-left">
                <img src="./assets/img/pb.webp" alt="About Us Image" />
                <div className="about-us-img-info about-us-img-info-2 about-us-img-info-3 d-none">
                  <div className="ltn__video-img ltn__animation-pulse1">
                    <img src="./assets/img/others/8.png" alt="video popup bg image" />
                    <a
                      className="ltn__video-icon-2 ltn__video-icon-2-border---"
                      href="https://www.youtube.com/embed/X7R-q9rsrtU?autoplay=1&showinfo=0"
                      data-rel="lightcase:myCollection">
                      <i className="fa fa-play" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="about-us-info-wrap">
                <div className="section-title-area ltn__section-title-2--- mb-30">
                  <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Naše vize</h6>
                  <h1 className="section-title">Ideální místo pro Vaše podnikání</h1>
                  <p>
                    Jsme tady, abychom Vám pomohli vytvořit ideální pracovní prostředí. Nabízíme moderní a flexibilní
                    řešení pro Vaše byznys potřeby.
                  </p>
                </div>
                <div className="ltn__feature-item ltn__feature-item-3">
                  <div className="ltn__feature-info">
                    <h4>Kancelářské prostory</h4>
                    <p>
                      Na základě domluvy Vám vyjdeme vstříc při úpravách dispozic zvoleného prostoru. Záleží jen na Vás,
                      jak velká a členitá bude Vaše nová kancelář.
                    </p>
                  </div>
                </div>
                <div className="ltn__feature-item ltn__feature-item-3">
                  <div className="ltn__feature-info">
                    <h4>
                      <a href="service-details.html">Zasedací a školící místnosti</a>
                    </h4>
                    <p>
                      Reprezentativní prostory různých velikostí Vám budou k dispozici s veškerým technickým vybavením.
                    </p>
                  </div>
                </div>
                <div className="ltn__feature-item ltn__feature-item-3">
                  <div className="ltn__feature-info">
                    <h4>
                      <a href="service-details.html">Obchodní prostory</a>
                    </h4>
                    <p>Svým zákazníkům můžete být blíže pomocí pronájmu dostupných prodejních prostor v naší budově.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
