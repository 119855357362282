import React, { Component } from "react";

class PortfolioGrid extends Component {
  render() {
    return (
      <div className="ltn__gallery-area mt-170 mb-120">
        <div className="container">
          {/* (ltn__gallery-info-hide) Class for 'ltn__gallery-item-info' not showing */}
          <div className="ltn__gallery-active row ltn__gallery-style-2 ltn__gallery-info-hide---">
            <div className="ltn__gallery-sizer col-1" />
            {/* gallery-item */}
            <div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
              <div className="ltn__gallery-item-inner">
                <div className="ltn__gallery-item-img">
                  <a href="./assets/img/portfolio/1.webp" data-rel="lightcase:myCollection">
                    <img src="./assets/img/portfolio/1.webp" alt="Image" />
                  </a>
                </div>
              </div>
            </div>
            {/* gallery-item */}
            <div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
              <div className="ltn__gallery-item-inner">
                <div className="ltn__gallery-item-img">
                  <a href="./assets/img/portfolio/2.webp" data-rel="lightcase:myCollection">
                    <img src="./assets/img/portfolio/2.webp" alt="Image" />
                  </a>
                </div>
              </div>
            </div>
            {/* gallery-item */}
            <div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
              <div className="ltn__gallery-item-inner">
                <div className="ltn__gallery-item-img">
                  <a href="./assets/img/portfolio/4.webp" data-rel="lightcase:myCollection">
                    <img src="./assets/img/portfolio/4.webp" alt="Image" />
                  </a>
                </div>
              </div>
            </div>
            {/* gallery-item */}
            <div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
              <div className="ltn__gallery-item-inner">
                <div className="ltn__gallery-item-img">
                  <a href="./assets/img/portfolio/3.webp" data-rel="lightcase:myCollection">
                    <img src="./assets/img/portfolio/3.webp" alt="Image" />
                  </a>
                </div>
              </div>
            </div>
            {/* gallery-item */}
            <div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
              <div className="ltn__gallery-item-inner">
                <div className="ltn__gallery-item-img">
                  <a href="./assets/img/portfolio/5.webp" data-rel="lightcase:myCollection">
                    <img src="./assets/img/portfolio/5.webp" alt="Image" />
                  </a>
                </div>
              </div>
            </div>
            {/* gallery-item */}
            <div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
              <div className="ltn__gallery-item-inner">
                <div className="ltn__gallery-item-img">
                  <a href="./assets/img/portfolio/6.webp" data-rel="lightcase:myCollection">
                    <img src="./assets/img/portfolio/6.webp" alt="Image" />
                  </a>
                </div>
              </div>
            </div>
            {/* gallery-item */}
            <div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
              <div className="ltn__gallery-item-inner">
                <div className="ltn__gallery-item-img">
                  <a href="./assets/img/portfolio/9.webp" data-rel="lightcase:myCollection">
                    <img src="./assets/img/portfolio/9.webp" alt="Image" />
                  </a>
                </div>
              </div>
            </div>
            {/* gallery-item */}
            <div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
              <div className="ltn__gallery-item-inner">
                <div className="ltn__gallery-item-img">
                  <a href="./assets/img/portfolio/10.webp" data-rel="lightcase:myCollection">
                    <img src="./assets/img/portfolio/10.webp" alt="Image" />
                  </a>
                </div>
              </div>
            </div>
            {/* gallery-item */}
            <div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
              <div className="ltn__gallery-item-inner">
                <div className="ltn__gallery-item-img">
                  <a href="./assets/img/portfolio/12.webp" data-rel="lightcase:myCollection">
                    <img src="./assets/img/portfolio/12.webp" alt="Image" />
                  </a>
                </div>
              </div>
            </div>
            {/* gallery-item */}
            <div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
              <div className="ltn__gallery-item-inner">
                <div className="ltn__gallery-item-img">
                  <a href="./assets/img/portfolio/14.webp" data-rel="lightcase:myCollection">
                    <img src="./assets/img/portfolio/14.webp" alt="Image" />
                  </a>
                </div>
              </div>
            </div>
            {/* gallery-item */}
            <div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
              <div className="ltn__gallery-item-inner">
                <div className="ltn__gallery-item-img">
                  <a href="./assets/img/portfolio/15.webp" data-rel="lightcase:myCollection">
                    <img src="./assets/img/portfolio/15.webp" alt="Image" />
                  </a>
                </div>
              </div>
            </div>
            {/* gallery-item */}
            <div className="ltn__gallery-item filter_category_3 col-lg-4 col-sm-6 col-12">
              <div className="ltn__gallery-item-inner">
                <div className="ltn__gallery-item-img">
                  <a href="./assets/img/portfolio/16.webp" data-rel="lightcase:myCollection">
                    <img src="./assets/img/portfolio/16.webp" alt="Image" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PortfolioGrid;
