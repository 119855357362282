import React, { Component } from "react";

class History extends Component {
  render() {
    return (
      <div className="ltn__about-us-area pb-160 go-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 align-self-center">
              <div className="about-us-img-wrap ltn__img-shape-left  about-img-left">
                <img src="./assets/img/about.webp" alt="Image" />
              </div>
            </div>
            <div className="col-lg-7 align-self-center">
              <div className="about-us-info-wrap">
                <div className="section-title-area ltn__section-title-2--- mb-20">
                  <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Naše historie</h6>
                  <h1 className="section-title">Spolu jsme PB-Centrum</h1>
                </div>
                <div className="about-us-info-wrap-inner about-us-info-devide---">
                  <p>
                    Jsme tým lidí, kteří věří v sílu podnikání a inovace. Naše společnost byla založena s vizí nabídnout
                    podnikatelům a firmám v Ústí nad Labem nejenom kancelářské prostory, ale také komunitní prostředí,
                    které inspiruje, spojuje a poskytuje vše, co potřebujete pro dosažení svých cílů.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default History;
