import React from "react";

import Navbar from "./global-components/Navbar";
import Footer from "./global-components/Footer";
import CallToAction from "./global-components/CallToAction";
import Banner from "./section-components/Banner";
import About from "./section-components/About";
import Neighbour from "./section-components/Neighbour";
import Companies from "./section-components/Companies";

const Home = () => {
  return (
    <>
      <Navbar />
      <Banner />
      <About />
      <Neighbour />
      <Companies />
      <CallToAction />
      <Footer />
    </>
  );
};

export default Home;
