import React from "react";

import Navbar from "./global-components/Navbar";
import PageHeader from "./global-components/PageHeader";
import CallToAction from "./global-components/CallToAction";
import Footer from "./global-components/Footer";
import Video from "./section-components/Video";
import Service from "./section-components/Service";
import Counter from "./section-components/Counter";

const Services = () => {
  return (
    <>
      <Navbar />
      <PageHeader headerTitle="Co Vám nabízíme?" />
      <Video />
      <Service />
      {/* <Counter /> */}
      <CallToAction />
      <Footer />
    </>
  );
};

export default Services;
