import React, { Component } from "react";
import { Link } from "react-router-dom";

class Neighbour extends Component {
  render() {
    return (
      <div className="neighbour-area section-bg-1 pt-118 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2--- text-center---">
                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Centrum dění</h6>
                <h1 className="section-title">Co se nachází v okolí?</h1>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="ltn__faq-inner ltn__faq-inner-2 ltn__faq-inner-3">
                <div className="row">
                  <div className="col-lg-6">
                    <div id="accordion_2">
                      {/* card */}
                      <div className="card">
                        <h6
                          className="collapsed ltn__card-title"
                          data-bs-toggle="collapse"
                          data-bs-target="#faq-item-2-1"
                          aria-expanded="false">
                          Zastávka MHD - 1 minuta
                        </h6>
                      </div>
                      {/* card */}
                      <div className="card">
                        <h6
                          className="collapsed ltn__card-title"
                          data-bs-toggle="collapse"
                          data-bs-target="#faq-item-2-2"
                          aria-expanded="false">
                          Centrum - 1 minuta
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div id="accordion_3">
                      {/* card */}
                      <div className="card">
                        <h6
                          className="collapsed ltn__card-title"
                          data-bs-toggle="collapse"
                          data-bs-target="#faq-item-3-4"
                          aria-expanded="false">
                          OC FORUM - 2 minuty
                        </h6>
                      </div>
                      {/* card */}
                      <div className="card">
                        <h6
                          className="ltn__card-title"
                          data-bs-toggle="collapse"
                          data-bs-target="#faq-item-3-5"
                          aria-expanded="false">
                          Vlakové nádraží - 2 minuty
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Neighbour;
