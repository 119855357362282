import React from "react";

import Navbar from "./global-components/Navbar";
import PageHeader from "./global-components/PageHeader";
import Footer from "./global-components/Footer";
import ContactInfo from "./section-components/ContactInfo";
import Map from "./section-components/Map";

const Contact = () => {
  return (
    <>
      <Navbar />
      <PageHeader headerTitle="Máte zájem o naše prostory?" />
      <ContactInfo />
      <Map />
      <Footer />
    </>
  );
};

export default Contact;
